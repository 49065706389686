<script setup lang="ts">
import { SwiperOptions } from 'swiper/types';

export type TopBannerEntry = {
  content: string;
  title: string;
  countdown_date?: Date;
  hide_banner_date?: Date;
  timer_expired_content?: string;
  timer_expired_link?: string;
  link: string;
};

type BannerEdge = {
  node: TopBannerEntry;
};

type TopBanner = {
  edges: BannerEdge[];
};

const props = defineProps({
  topBanner: {
    type: Object as () => TopBanner,
    default: () => ({}),
  },
});

const banners = computed(() => [...(props.topBanner?.edges || [])]);
const isMultiple = computed(() => banners.value.length > 1);
const tbannerSwiperPagination = ref<HTMLElement | null>(null);
const swiperOptions: SwiperOptions = {
  navigation: {
    nextEl: '.tbanner-btn-next',
    prevEl: '.tbanner-btn-prev',
  },
  centeredSlides: true,
  loop: true,
  a11y: {
    enabled: true,
  },
  autoplay: isMultiple.value
    ? {
        delay: 7500,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      }
    : false,
  autoHeight: true,
  pagination: {
    type: 'bullets',
    clickable: true,
  },
};
</script>

<template>
  <WrapperSwiper
    v-if="banners?.length > 1"
    :class="[{ 'tbanner-swiper-container': isMultiple }]"
    :swiper-options="swiperOptions"
  >
    <swiper-slide v-for="(bannerEdge, i) in banners" :key="`${i}-${bannerEdge.node.content}`">
      <TopBanner :top-banner="bannerEdge.node" />
    </swiper-slide>
    <template v-if="isMultiple" #end>
      <div ref="tbannerSwiperPagination" class="tbanner-swiper-pagination swiper-tab-mob-only" />
      <div class="tbanner-btn-prev swiper-desk-and-tab-only">
        <ArrowLeftLine />
      </div>
      <div class="tbanner-btn-next swiper-desk-and-tab-only">
        <ArrowRightLine />
      </div>
    </template>
  </WrapperSwiper>
  <TopBanner v-else-if="banners?.length === 1" :top-banner="banners?.[0]?.node" />
</template>

<style lang="scss" scoped>
.tbanner-swiper-container {
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  list-style: none;
  position: sticky;
  top: 0px;
  padding: 0;
  z-index: 3;
}

.tbanner-swiper-pagination {
  position: absolute;
  bottom: 0;
  z-index: 10;
  display: flex !important;
  justify-content: center;
  height: 0.5rem;
  width: 100%;

  :deep(.tbanner-pag-bullet) {
    background-color: gray;
    height: 0.25rem;
    width: 1rem;
    display: inline-block;
    border-radius: 25%;
    cursor: pointer;
    opacity: 1;
    margin: 0 0.25rem;

    &.tbanner-pag-current {
      background-color: white;
      cursor: initial;
    }
  }

  span {
    height: 100%;
  }

  // @include local-mixins.desktop_and_tablet {
  //   display: none !important;
  // }
}

.swiper-navs {
  height: 100%;
  position: absolute;
  top: 0;
}

.tbanner-btn-next,
.tbanner-btn-prev {
  // display: none !important;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  color: none;
  position: absolute;
  z-index: 10;
  top: 0;

  :deep(svg) {
    fill: $color-neutral-white;
  }

  &.swiper-button-disabled {
    :deep(svg) {
      fill: $color-neutral-cool-300;
    }
  }

  &:after {
    content: none;
  }

  // @include local-mixins.desktop_and_tablet {
  //   display: flex !important;
  // }
}
.tbanner-btn-prev {
  margin-right: 1rem;
  left: 1rem;
  right: auto;
}
.tbanner-btn-next {
  margin-left: 1rem;
  right: 1rem;
  left: auto;
}

swiper-container::part(bullet),
swiper-container::part(bullet-active) {
  opacity: 50%;
  margin-top: 10px;
}
swiper-container::part(bullet-active) {
  background-color: $color-primary-500;
}
swiper-container::part(bullet) {
  background-color: $color-neutral-cool-600;
}
</style>
